import Typography from '@mui/material/Typography';

export default function TypographyUI({ font_style,children, ...props }) {

  function fontStyle() {
    if (font_style) {
      if (font_style.md) {
        const fontSize = font_style.xs.split("-")[0] + "px";
        const fontWeight = font_style.xs.split("-")[1];
        const mdFontSize = font_style.md.split("-")[0] + "px";
        const mdFontWeight = font_style.md.split("-")[1];
        return {
          fontSize: { md: mdFontSize, xs: fontSize },
          fontWeight: { md: mdFontWeight, xs: fontWeight },
          fontFamily: "inherit !important",
        };
      } else {
        fontStyle.fontSize = font_style.split("-")[0] + "px";
        fontStyle.fontWeight = font_style.split("-")[1];
        return {
          fontSize: font_style.split("-")[0] + "px",
          fontWeight: font_style.split("-")[1],
          fontFamily: "inherit !important",
        };
      }
    }
  }
  return (
    <Typography {...props}  sx={{...props.style,...props.sx,...fontStyle()}}>
      {children}
    </Typography>
  );
}
