"use client"
import { CircularProgress } from "@mui/material";
import ContainerUI from "../Layout/Container";
import ItemUI from "../Layout/Item";
import TypographyUI from "../Typography";
export default function PageLoader({loaderText="Loading",...props}) {
  return (
    <div
      style={{
        // height: "100vh",
        position: "fixed",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        backgroundColor: "rgba(0, 0, 0, 0.50)",
        zIndex:99999
      }}
    >
      {/* <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "grid",
          placeItems: "center",
          display:"none",
        }}
      >
        <svg style={{ width: "0px", height: "0px" }}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop
                offset="0%"
                stopColor="rgb(12 193 211)" 
              />
              <stop
                offset="100%"
                stopColor="rgb(159 212 61)"
              />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size={"60px"}
          sx={{
            "svg circle": {
              stroke: "url(#my_gradient)",
            },
            filter: "drop-shadow(0px 0px 35px white)",
          }}
        /> */}
        {/* #b8f67c */}
      {/* </div> */}
      <ContainerUI 
      sx={{
        justifyContent:"center",
        flexDirection:"column",
        padding:"24px",
        gap:"12px",
        textAlign:"center",
        alignItems:"center",
        borderRadius:"8px",
        background:"#FFF",
        minHeight:{xs:"168px"},
        maxWidth:{xs:"358px"},
        width: {md:"358px", xs:"90vw", sm:"358px"},
        // width:"auto",
        heigth:"auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        }}>
        <ItemUI>
          <svg style={{ width: "0px", height: "0px" }}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop
                offset="0%"
                stopColor="rgb(12 193 211)" 
              />
              <stop
                offset="100%"
                stopColor="rgb(159 212 61)"
              />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size={"60px"}
          sx={{
            "svg circle": {
              stroke: "url(#my_gradient)",
            },
            filter: "drop-shadow(0px 0px 35px white)",
          }}/>
        </ItemUI>
        <ItemUI>
          <TypographyUI font_style={"22-500"}>{loaderText}</TypographyUI>
        </ItemUI>
      </ContainerUI>
    </div>
  );
}
